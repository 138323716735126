import { DISCOUNT_TYPE } from 'common/enums';
// import { RECURRENCE_VALUES } from 'common/enums/class.enum';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';
import { DiscountApplied } from 'common/interfaces/bookingClass.interface';
import { IRPEnrollmentClass } from 'common/interfaces/responsiblePerson.interface';
import { IBookingData, ISchedule } from 'common/interfaces/schedules.interface';
import { ISession } from 'common/interfaces/session.interface';
import dayjs from 'dayjs';
import {
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE
} from 'pages/reports/constant';

export const getNewEnrolmentSessions = (
  item: IBookingData,
  sessions: Array<ISession>
): Array<ISession> => {
  if (!item.classInfo || !item?.schedules) return [];
  // Case intensive
  if (item?.enrollmentType === BOOKING_TYPE.HOLIDAY_PROGRAM) {
    return item?.schedules.filter((session) => session?.checked) || [];
  }

  // Case DD
  if (
    item?.enrollmentType === BOOKING_TYPE.ONGOING_CLASS &&
    item?.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT
  ) {
    return (
      sessions.filter(
        (session) =>
          dayjs(session.startTime).format('ddd') ===
          dayjs(item?.schedules[0]?.startTime).format('ddd')
      ) || []
    );
  }

  // Case PIF
  // const termActive = item.classInfo.template.terms.find(
  //   (term) => term?.termId === item?.schedules?.[0]?.termId
  // );

  // const endDateOfTerm = dayjs(termActive?.termDetail?.endDate)?.format(
  //   'YYYY-MM-DD'
  // );

  // const endDateOfTerm = dayjs(item.classInfo.recurrence.endDate).format(
  //   'YYYY-MM-DD'
  // );

  // const endDateOfClass =
  //   item.classInfo?.recurrence?.type === RECURRENCE_VALUES.DO_NOT_REPEAT
  //     ? dayjs(item?.schedules[0]?.startTime).format('YYYY-MM-DD')
  //     : dayjs(endDateOfTerm).format('YYYY-MM-DD 23:59:59');

  let endDate = dayjs(item.classInfo.recurrence.endDate).format(
    'YYYY-MM-DD 23:59:59'
  );

  const result: Array<ISession> = [];

  for (const session of sessions) {
    if (dayjs(session?.startTime).isAfter(endDate)) break;

    if (
      !dayjs(session?.startTime).isBefore(item?.schedules?.[0]?.startTime) &&
      !dayjs(session?.startTime).isAfter(endDate) &&
      dayjs(session.startTime).format('ddd') ===
        dayjs(item?.schedules[0]?.startTime).format('ddd')
    ) {
      result.push(session);
    }
  }

  return result;
};

export const calculateExtraPrice = (
  currentEnrolment: IRPEnrollmentClass,
  newEnrolment: IBookingData,
  newEnrolmentSessions: Array<ISession>,
  nextDebitDay: string,
  discountApplied?: DiscountApplied
): { extraFee: number; numberOfFreeLessonFromMove?: number } => {
  // --------- Common variables for calculation ---------
  const today: dayjs.Dayjs = dayjs();
  const firstLessonDate: dayjs.Dayjs = dayjs(
    newEnrolment.schedules?.[0]?.startTime
  ).subtract(1, 'minute');
  const [startOfWeek, endOfWeek] = getStartEndDateOfWeek(today.toDate());

  let isMustAttendCurrentWeek: boolean = false;

  let extraFee: number = 0;
  let numberOfFreeLessonFromMove: number = 0;
  // --------- End common variables for calculation ---------

  // Get booking type of current and new enrolment
  const currentEnrolmentBookingType = currentEnrolment?.type;
  const newEnrolmentBookingType = newEnrolment?.enrollmentType;

  // Get PAYMENT TYPE from current and new enrolment.
  const currentEnrolmentPayment = currentEnrolment?.paymentType;
  const newEnrolmentPayment = newEnrolment?.paymentOption;

  if (newEnrolmentPayment === PAYMENT_VALUE.IDLE) return { extraFee: 0 };

  // --------------------------------------------------------------------
  // Get current sessions

  const currentSessions =
    currentEnrolmentPayment === PAYMENT_VALUE.DIRECT_DEBIT
      ? currentEnrolment?.allSessions
      : currentEnrolment?.actualSessions;

  // FROM BOOKING TYPE WE HAVE 2 CASES.

  // Weekly LTS to Weekly LTS
  if (
    currentEnrolmentBookingType === BOOKING_TYPE.ONGOING_CLASS &&
    newEnrolmentBookingType === BOOKING_TYPE.ONGOING_CLASS
  ) {
    // From current sessions return sessions in this week. Can be []
    const sessionsInCurrentWeek: Array<ISession> = currentSessions?.filter(
      (session) => {
        return (
          dayjs(session.endTime).isAfter(startOfWeek) &&
          dayjs(session.endTime).isBefore(endOfWeek)
        );
      }
    );

    // Check if isMustAttendCurrentWeek
    const sessionsNotLearnInThisWeek = sessionsInCurrentWeek.filter(
      (session) =>
        dayjs(session?.startTime).isBefore(endOfWeek) &&
        dayjs(session.startTime).isAfter(today)
    );
    if (
      sessionsNotLearnInThisWeek?.length &&
      dayjs(newEnrolmentSessions?.[0]?.startTime).isAfter(endOfWeek)
    ) {
      isMustAttendCurrentWeek = true;
    } else {
      isMustAttendCurrentWeek = false;
    }
    // --------------------------------------------------------------------
    // FROM PAYMENT TYPE WE HAVE 4 CASES.
    if (
      currentEnrolmentBookingType === BOOKING_TYPE.ONGOING_CLASS &&
      newEnrolmentBookingType === BOOKING_TYPE.ONGOING_CLASS
    ) {
      // Case 1 + 2:
      if (currentEnrolmentPayment === PAYMENT_VALUE.DIRECT_DEBIT) {
        // Case 1: Move DD to DD
        if (newEnrolmentPayment === PAYMENT_VALUE.DIRECT_DEBIT) {
          // Count lesson of new class
          const newEnrolmentSessionsUntilNextDebitDay =
            newEnrolmentSessions.filter(
              (schedule) =>
                dayjs(schedule.startTime).isAfter(firstLessonDate) &&
                dayjs(schedule.startTime).isBefore(nextDebitDay) &&
                dayjs(schedule.startTime).format('ddd') ===
                  dayjs(newEnrolment?.schedules[0]?.startTime).format('ddd')
            );

          // Count lessons of old class for deduct
          const currentSessionsNotAttendUntilNextDebitDay =
            currentSessions.filter(
              (session) =>
                dayjs(session.startTime).isAfter(today) &&
                dayjs(session.startTime).isBefore(nextDebitDay)
            );
          numberOfFreeLessonFromMove =
            currentSessionsNotAttendUntilNextDebitDay.length -
            newEnrolmentSessionsUntilNextDebitDay.length;
          if (
            discountApplied &&
            discountApplied.discountType === DISCOUNT_TYPE.COMPLIMENTARY
          ) {
            const {
              startDateFree,
              endDateFree,
              duration,
              remainFreeLesson = 0
            } = discountApplied;
            if (duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
              extraFee =
                (newEnrolmentSessionsUntilNextDebitDay?.length -
                  currentSessionsNotAttendUntilNextDebitDay?.length -
                  remainFreeLesson) *
                newEnrolment.classInfo?.price;
              if (extraFee < 0) {
                extraFee = 0;
              }
            } else if (duration === VOUCHER_DURATION.TIME_RANGE) {
              let endDate = endDateFree || nextDebitDay;
              if (dayjs(endDate).isAfter(nextDebitDay)) {
                endDate = nextDebitDay;
              }
              const lessonsFreeInCurrentClass = getLessonsDayInRange(
                currentSessionsNotAttendUntilNextDebitDay as ISchedule[],
                dayjs(startDateFree).format(),
                dayjs(endDate).format(),
                []
              );
              const lessonsFreeInNewClass = getLessonsDayInRange(
                newEnrolmentSessionsUntilNextDebitDay as ISchedule[],
                dayjs(startDateFree).format(),
                dayjs(endDate).format(),
                []
              );
              extraFee =
                (newEnrolmentSessionsUntilNextDebitDay?.length -
                  lessonsFreeInNewClass.length -
                  (currentSessionsNotAttendUntilNextDebitDay?.length -
                    lessonsFreeInCurrentClass.length)) *
                newEnrolment.classInfo?.price;
              if (extraFee < 0) {
                extraFee = 0;
              }
            }
          } else {
            // extra fee = (total new lessons until next DD - total old lesson not attended until next DD) * price
            extraFee =
              (newEnrolmentSessionsUntilNextDebitDay?.length -
                currentSessionsNotAttendUntilNextDebitDay?.length) *
              newEnrolment.classInfo?.price;
            if (extraFee < 0) {
              extraFee = 0;
            }
          }
        }
        // Case 2: Move DD to PIF
        else if (newEnrolmentPayment === PAYMENT_VALUE.UPFRONT) {
          // Count lessons of old class for deduct
          let currentSessionsNotAttendUntilNextDebitDay =
            currentSessions.filter(
              (session) =>
                dayjs(session.startTime).isAfter(today) &&
                dayjs(session.startTime).isBefore(nextDebitDay)
            );

          if (isMustAttendCurrentWeek) {
            currentSessionsNotAttendUntilNextDebitDay =
              currentSessionsNotAttendUntilNextDebitDay.filter((session) => {
                return dayjs(session?.startTime).isAfter(endOfWeek);
              });
          }
          if (
            discountApplied &&
            discountApplied.discountType === DISCOUNT_TYPE.COMPLIMENTARY
          ) {
            const {
              startDateFree,
              endDateFree,
              duration,
              remainFreeLesson = 0
            } = discountApplied;
            if (duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
              extraFee =
                (newEnrolmentSessions?.length -
                  currentSessionsNotAttendUntilNextDebitDay?.length -
                  remainFreeLesson) *
                newEnrolment.classInfo?.price;
              if (extraFee < 0) {
                extraFee = 0;
              }
            } else if (duration === VOUCHER_DURATION.TIME_RANGE) {
              let endDate = '';
              if (!endDateFree) {
                endDate = nextDebitDay;
              } else {
                endDate = dayjs(endDateFree).isAfter(dayjs(nextDebitDay))
                  ? nextDebitDay
                  : endDateFree;
              }
              const endDateForCurrentClass = endDate
                ? endDate
                : currentEnrolment.allSessions[
                    currentEnrolment.allSessions.length - 1
                  ]?.endTime;

              const endDateForNewClass = endDateFree
                ? endDateFree
                : newEnrolmentSessions?.[newEnrolmentSessions.length - 1]
                    ?.startTime;
              const lessonsFreeInCurrentClass = getLessonsDayInRange(
                currentSessionsNotAttendUntilNextDebitDay as ISchedule[],
                dayjs(startDateFree).format(),
                dayjs(endDateForCurrentClass).format(),
                []
              );
              const lessonsFreeInNewClass = getLessonsDayInRange(
                newEnrolmentSessions as ISchedule[],
                dayjs(startDateFree).format(),
                dayjs(endDateForNewClass).format(),
                []
              );
              extraFee =
                (newEnrolmentSessions?.length -
                  lessonsFreeInNewClass.length -
                  (currentSessionsNotAttendUntilNextDebitDay?.length -
                    lessonsFreeInCurrentClass.length)) *
                newEnrolment.classInfo?.price;
              if (extraFee < 0) {
                extraFee = 0;
              }
            }
          } else {
            extraFee =
              (newEnrolmentSessions?.length -
                currentSessionsNotAttendUntilNextDebitDay.length) *
              newEnrolment.classInfo?.price;
            if (extraFee < 0) {
              extraFee = 0;
            }
          }
        }
      }

      // Case 3 + 4:
      else if (currentEnrolmentPayment === PAYMENT_VALUE.UPFRONT) {
        // Case 3: Move PIF to DD
        if (newEnrolmentPayment === PAYMENT_VALUE.DIRECT_DEBIT) {
          let currentSessionsRemain = currentSessions.filter((session) =>
            dayjs(session?.startTime).isAfter(today)
          );

          const newEnrolmentSessionsUntilNextDebitDay =
            newEnrolmentSessions.filter((session) =>
              dayjs(session?.startTime).isBefore(nextDebitDay)
            );

          if (isMustAttendCurrentWeek) {
            currentSessionsRemain = currentSessionsRemain.filter((session) => {
              return dayjs(session?.startTime).isAfter(nextDebitDay);
            });
          }

          if (
            currentSessionsRemain?.length -
              newEnrolmentSessionsUntilNextDebitDay?.length <
            0
          ) {
            extraFee =
              (newEnrolmentSessionsUntilNextDebitDay?.length -
                currentSessionsRemain?.length) *
              newEnrolment.classInfo?.price;
          }
        }
        // Case 4: Move PIF to PIF
        else if (newEnrolmentPayment === PAYMENT_VALUE.UPFRONT) {
          let currentSessionsNotAttendUntilNextDebitDay =
            currentSessions.filter((session) =>
              dayjs(session.startTime).isAfter(
                isMustAttendCurrentWeek ? endOfWeek : today
              )
            );

          extraFee =
            (newEnrolmentSessions?.length -
              currentSessionsNotAttendUntilNextDebitDay.length) *
            newEnrolment.classInfo?.price;
          if (extraFee < 0) {
            extraFee = 0;
          }
        }
      }
    }
  }

  // Intensive LTS to Intensive LTS
  if (
    currentEnrolmentBookingType === BOOKING_TYPE.HOLIDAY_PROGRAM &&
    newEnrolmentBookingType === BOOKING_TYPE.HOLIDAY_PROGRAM
  ) {
    // Instead of throw error
    // Return the extra fee = 0 to prevent crash app
    if (currentSessions?.length === 0 || newEnrolmentSessions?.length === 0) {
      return { extraFee: 0 };
    }
    // just can move before start date more than 1 week
    const firstSession = currentSessions?.[0];
    if (dayjs().add(7, 'day').isAfter(firstSession?.startTime)) {
      return { extraFee: 0 };
    }
    if (dayjs(firstSession?.startTime).isBefore(today)) {
      return { extraFee: 0 };
    }

    extraFee =
      (newEnrolmentSessions?.length - currentSessions?.length) *
      newEnrolment.classInfo?.price;
    if (extraFee < 0) {
      extraFee = 0;
    }
  }

  return { extraFee, numberOfFreeLessonFromMove };
};

export const getStartEndDateOfWeek = (date: Date): string[] => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  const dayOfWeek = startDate.getDay();
  const startDay = startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  startDate.setDate(startDay);
  return [
    dayjs(startDate).format(FORMAT_START_OF_DATE),
    dayjs(startDate).add(6, 'day').format(FORMAT_END_OF_DATE)
  ];
};
